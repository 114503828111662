import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../../context/UserContext';


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useContext(UserContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axios.post('https://api-advert.westapp.com.ng/api/login', { email, password });
      login(data);
      navigate('/dashboard');
    } catch (err) {
      setError('Invalid credentials');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid d-flex align-items-center justify-content-center" style={{ height: '100vh', background: 'linear-gradient(to right, #155799, #159957)' }}>
      <div className="card shadow-lg border-0 rounded-4 p-4" style={{ width: '400px', backgroundColor: 'white',  height: '70vh',}}>
        <div className="card-header bg-transparent text-center border-0">
          <h3 className="text-success fw-bold">Welcome Back</h3>
          <p className="text-muted">Sign in to your account</p>
        </div>

        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group mb-3">
              <label htmlFor="email" className="form-label fw-semibold">Email Address</label>
              <input
                type="email"
                id="email"
                className="form-control rounded-3"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="password" className="form-label fw-semibold">Password</label>
              <input
                type="password"
                id="password"
                className="form-control rounded-3"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            {error && <div className="alert alert-danger text-center py-2">{error}</div>}

            <button type="submit" className="btn btn-success w-100 rounded-3 py-2 fw-bold" disabled={loading}>
              {loading ? 'Logging in...' : 'Login'}
            </button>
          </form>
        </div>

        <div className="card-footer bg-transparent text-center border-0">
          <a href="/reset-password" className="text-success fw-semibold">Forgot Password?</a>
        </div>
      </div>
    </div>
  );
};

export default Login;